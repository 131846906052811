import './PageNotFound.css';

const PageNotFound = () => {
    return (
        <div id="main">
            <div class="fof">
                <h1>Error 404 - Page Not Found</h1>
                <h2>or...It hasn't been developed yet.</h2>
            </div>
        </div>
    )
}

export default PageNotFound